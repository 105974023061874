import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Error from "../components/Error.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/index",
    meta: { title: "首页-学习精灵" },

    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/home/home.vue"),
        meta: { title: "首页-学习精灵" },
      },
      {
        path: "/course",
        name: "course",
        component: () => import("../views/home/course.vue"),
        meta: { title: "课程中心-学习精灵", mustLog: true },
      },
      {
        path: "/coursePlayer",
        name: "coursePlayer",
        component: () => import("../views/course/coursePlayer.vue"),
        meta: { title: "观看课程-学习精灵", mustLog: true },
      },
      {
        path: "/courseDetail",
        name: "courseDetail",
        component: () => import("../views/home/courseDetail.vue"),
        meta: { title: "课程详情-学习精灵", mustLog: true },
      },
      {
        path: "/znlx",
        name: "znlx",
        component: () => import("../views/home/znlx.vue"),
        meta: { title: "智能练习-学习精灵", mustLog: true },
      },
      {
        path: "/zytb",
        name: "zytb",
        component: () => import("../views/home/zytb.vue"),
        meta: { title: "志愿填报-学习精灵", mustLog: true },
      },
      {
        path: "/gkjyz",
        name: "gkjyz",
        component: () => import("../views/home/gkjyz.vue"),
        meta: { title: "中考加油站-学习精灵", mustLog: true },
      },
      {
        path: "/zxwd",
        name: "zxwd",
        component: () => import("../views/home/zxwd.vue"),
        meta: { title: "在线问答-学习精灵", mustLog: true },
      },
      {
        path: "/privacy",
        name: "privacy",
        component: () => import("../views/public/privacy.vue"),
        meta: { title: "隐私政策-学习精灵" },
      },
      {
        path: "/service",
        name: "service",
        component: () => import("../views/public/service.vue"),
        meta: { title: "服务协议-学习精灵" },
      },
      {
        path: "/course_tow",
        name: "course_tow",
        component: () => import("../views/course/course_tow.vue"),
        meta: { title: "精品专题-学习精灵", mustLog: true },
      },
      {
        path: "/course_one",
        name: "course_one",
        component: () => import("../views/course/course_one.vue"),
        meta: { title: "新课标课程-学习精灵", mustLog: true },
      },
      {
        path: "/course_index",
        name: "course_index",
        component: () => import("../views/course/course_index.vue"),
        meta: { title: "课程中心-学习精灵", mustLog: true },
      },
      {
        path: "/fxbk",
        name: "fxbk",
        component: () => import("../views/course/fxbk.vue"),
        meta: { title: "学习记录-学习精灵", mustLog: true },
      },
      {
        path: "/jqjh",
        name: "jqjh",
        component: () => import("../views/course/jqjh.vue"),
        meta: { title: "假期计划-学习精灵", mustLog: true },
      },
      {
        path: "/jqjh_nav/:title",
        name: "jqjh_nav",
        component: () => import("../views/course/jqjh_nav.vue"),
        meta: { title: "假期计划-学习精灵", mustLog: true },
      },
      {
        path: "/jqjh_list",
        name: "jqjh_list",
        component: () => import("../views/course/jqjh_list.vue"),
        meta: { title: "假期计划-学习精灵", mustLog: true },
      },
      {
        path: "/zsdzd",
        name: "zsdzd",
        component: () => import("../views/practice/zsdzd.vue"),
        meta: { title: "知识点诊断-学习精灵", mustLog: true },
      },
      {
        path: "/zxst",
        name: "zxst",
        component: () => import("../views/practice/onlineQuestion.vue"),
        meta: { title: "在线刷题-学习精灵", mustLog: true },
      },
      {
        path: "/stlx",
        name: "stlx",
        component: () => import("../views/practice/classRoom.vue"),
        meta: { title: "随堂练习-学习精灵", mustLog: true },
      },
      {
        path: "/lnzt",
        name: "lnzt",
        component: () => import("../views/practice/historical.vue"),
        meta: { title: "历年真题-学习精灵", mustLog: true },
      },
      {
        path: "/jpsj",
        name: "jpsj",
        component: () => import("../views/practice/quality.vue"),
        meta: { title: "精品试卷-学习精灵", mustLog: true },
      },
      {
        path: "/dotrain",
        name: "dotrain",
        component: () => import("../views/practice/dotrain.vue"),
        meta: { title: "知识点诊断-学习精灵", mustLog: true },
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/home/about.vue"),
        meta: { title: "关于我们-学习精灵", mustLog: true },
      },
      {
        path: "/userInfo",
        name: "userInfo",
        component: () => import("../views/user/userInfo.vue"),
        redirect: "/baseInfo",
        meta: { title: "个人资料-学习精灵", mustLog: true },
        children: [
          {
            path: "/baseInfo",
            name: "baseInfo",
            component: () => import("../views/user/baseInfo.vue"),
            meta: { title: "个人资料-学习精灵", mustLog: true },
          },
          {
            path: "/userzsdzd",
            name: "userzsdzd",
            component: () => import("../views/user/userzsdzd.vue"),
            meta: { title: "知识点诊断-学习精灵", mustLog: true },
          },
          {
            path: "/wrongbook",
            name: "wrongbook",
            component: () => import("../views/user/wrongbook.vue"),
            meta: { title: "错题本-学习精灵", mustLog: true },
          },
          {
            path: "/educard",
            component: () => import("../views/user/educard.vue"),
            meta: { title: "学习卡-学习精灵", mustLog: true },
          },
          {
            path: "/letter",
            component: () => import("../views/user/letter.vue"),
            meta: { title: "消息中心-学习精灵", mustLog: true },
          },
          {
            path: "/myfav",
            component: () => import("../views/user/myfav.vue"),
            meta: { title: "收藏夹-学习精灵", mustLog: true },
          },
          {
            path: "/mywish",
            component: () => import("../views/user/mywish.vue"),
            meta: { title: "志愿表-学习精灵", mustLog: true },
          },
          {
            path: "/safty",
            component: () => import("../views/user/safty.vue"),
            meta: { title: "账号安全-学习精灵", mustLog: true },
          },
          {
            path: "/tasks",
            component: () => import("../views/user/tasks.vue"),
            meta: { title: "在线作业-学习精灵", mustLog: true },
          },
          {
            path: "/testreport",
            component: () => import("../views/user/testreport.vue"),
            meta: { title: "测评报告-学习精灵", mustLog: true },
          },
          {
            path: "/trainchart",
            component: () => import("../views/user/trainchart.vue"),
            meta: { title: "学习记录-学习精灵", mustLog: true },
          },
        ],
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: Error,
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/course/search.vue"),
    meta: { title: "搜索结果-学习精灵" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/public/login.vue"),
    meta: { title: "登录-学习精灵" },
  },
  {
    path: "/allCategories",
    name: "allCategories",
    component: () => import("../views/allCategories.vue"),
    meta: { title: "全部分类-学习精灵", mustLog: true },
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/public/forget.vue"),
    meta: { title: "忘记密码-学习精灵" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/public/register.vue"),
    meta: { title: "在线注册-学习精灵" },
  },
  {
    path: "/onnumber",
    name: "onnumber",
    component: () => import("../views/public/onnumber.vue"),
    meta: { title: "会员激活-学习精灵" },
  },
  {
    path: "/xlfm",
    name: "xlfm",
    component: () => import("../views/home/xlfm.vue"),
    meta: { title: "心灵FM-学习精灵", mustLog: true },
  },
  {
    path: "/appdownload",
    name: "appdownload",
    component: () => import("../views/home/appdownload.vue"),
    meta: { title: "下载-学习精灵" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    window.location.replace("/h5/index.html");
  } else {
  }
  // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  // if (flag) {
  //   window.open('https://51gaokao211.com/h5/index.html')
  // }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path === "/login") {
    // window.localStorage.removeItem('schools');
    next();
    return;
  }
  if (to.meta.mustLog) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const tokenStr = window.sessionStorage.getItem("token");
    if (!tokenStr & (to.path !== "/")) return next("/login");
    if (!userInfo.card_number || !userInfo.hasTime) return next("/onnumber");
  }

  next();
});

export default router;
