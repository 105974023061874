<template>
  <div class="home">
    <!-- header -->
    <el-header style="height: 100%">
      <div class="header-top">
        <div class="header-inner">
          <div class="inner-left">欢迎进入学习精灵官方网站！</div>
          <div class="inner-right">
            <ul class="btns">
              <li
                class="btn-item"
                v-for="(e, i) in headerBtns"
                :key="i"
                @click="routePush(e)"
              >
                <img v-if="e.icon" :src="e.icon" alt="" />
                <span>{{ e.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 顶部搜索 -->
      <div class="header-logo">
        <router-link to="/">
          <img src="../assets/logo.png" class="logo" />
        </router-link>
        <div class="search-box" :style="{ borderColor: themeColor }">
          <el-dropdown trigger="click" @command="searchDrop">
            <span class="el-dropdown-link" style="cursor: pointer">
              {{ options[opti].label
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.value"
                v-for="item in options"
                :key="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>

          <input
            type="text"
            placeholder="请输入您要搜索的课程"
            @keyup.enter="search"
            v-model.trim="searchForm.text"
          />
          <el-button
            class="submit-button"
            type="primary"
            :style="{
              'background-color': themeColor,
              'border-color': themeColor,
            }"
            @click="search"
            >搜索</el-button
          >
        </div>
        <h3 style="margin-left: 20px; color: #303133">{{ userInfo.school }}</h3>
      </div>
    </el-header>
    <div class="nav">
      <div class="nav-top"></div>
      <div class="nav-box">
        <ul class="tabs">
          <li
            class="tab tab-index0"
            @click="routePush({ link: '/course_index' })"
          >
            全部学科分类
          </li>
          <li
            class="tab"
            v-for="(e, i) in tabs"
            :class="e.isActive ? 'tab-select' : ''"
            :key="i"
            @click="tabClick(e, i)"
          >
            <span v-if="!e.data"> {{ e.name }}</span>
            <!-- <span v-else>{{ e.name }}</span> -->
            <el-dropdown
              v-else
              :show-timeout="50"
              @command="handleCommand"
              trigger="click"
            >
              <span type="primary">
                {{ e.name }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in e.data"
                  :key="item.id"
                  :command="JSON.stringify(item)"
                >
                  <span v-if="!item.data"> {{ item.name }}</span>
                  <el-dropdown
                    v-else
                    :show-timeout="50"
                    @command="handleCommand"
                    placement="right-start"
                  >
                    <span type="primary">
                      {{ item.name }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="item1 in item.data"
                        :key="item1.id"
                        :command="JSON.stringify(item1)"
                        >{{ item1.name }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <div class="subs"></div>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <footer class="foot">
      <div class="foot-inner">
        <div class="foot-top">
          <div class="foot-link-main">
            <p>友情链接：</p>
            <ul>
              <li v-for="(link, index) in links" :key="index">
                <a :href="getAllHref(link.url)" target="_blank">{{
                  link.name
                }}</a>
                <el-divider
                  direction="vertical"
                  v-if="index < links.length - 1"
                ></el-divider>
              </li>
            </ul>
          </div>
          <div class="foot-qrcode">
            <div class="telephone">
              <div class="tel"><span>客服电话：</span>400-6030-985</div>
              <div class="time">
                <span>服务时间：</span>08:30~12:00 13:30~17:30(周一至周五)
              </div>
            </div>
            <div class="down-app">
              <img :src="getImgSrc('/static/images/app.png')" alt="" />
              <span>下载APP</span>
            </div>
          </div>
        </div>
        <div class="foot-bot">
          <div class="bot-left" v-if="sites">
            <p>{{ sites ? sites.copyright.name : "" }}</p>
            <p>
              备案号：{{ sites ? sites.gongan.name : "" }}
              {{ sites ? sites.icp.name : "" }}
            </p>
          </div>
          <div class="bot-right">
            <p>
              <span>违法和不良信息举报电话: 18617814232</span>
              <span>举报邮箱: 2390691438@qq.com</span>
            </p>
            <a href="https://www.12377.cn/" target="_blank"
              >网上有害信息举报入口></a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "../store";
import CommonUtil from "../utils/common_util";
import http from "../utils/http";
export default {
  name: "HomeView",
  data() {
    return {
      setPhoneNum: CommonUtil.setPhoneNumSecret,
      getAllHref: CommonUtil.getAllHref,
      getImgSrc: CommonUtil.getImgSrc,
      searchForm: {
        course: "1",
        text: "",
      },
      themeColor: "",
      options: [
        {
          value: "1",
          label: "课程",
        },
        // {
        //   value: "2",
        //   label: "知识点",
        // },
      ],
      opti: 0,
      swiperArr: [
        "https://www.gaokao66.com/./uploads/images/20210806/610d3bbddf501.jpg",
        "https://www.gaokao66.com/./uploads/images/20210913/613f108676853.jpg",
        "https://www.gaokao66.com/./uploads/images/20230520/64688aa526756.jpg",
      ],
      tabs: [],
      links: [],
      sites: null,
    };
  },
  async mounted() {
    this.themeColor = this.$store.state.themeColor;
    store.commit("topBtn");
    await this.getNavBrowse();
    await this.getLinks();
    await this.getSites();
  },
  computed: {
    ...mapState(["hasLogin", "userInfo", "headerBtns"]),
  },
  methods: {
    searchDrop(command) {
      // let item = JSON.parse(command)
      // console.log(item)
    },
    allCategories() {
      this.$router.push("/allCategories");
    },
    routePush(e) {
      this.$router.push(e.link);
    },
    search() {
      console.log(this.searchForm);
      this.$router.push({
        name: "search",
        params: {
          title: "搜索结果",
          name: this.searchForm.text,
        },
      });
    },
    routerPush(routeAddress) {
      this.$router.push(routeAddress);
    },
    tabClick(e, i) {
      if (e.name == "智能练习") return;
      if (e.name == "学习广场") return;
      // console.log(this.$router.options.routes)
      let bol = this.$router.options.routes.some((m) => m.name == e.name);
      if (!bol) {
        if (e.alias) {
          this.routerPush("/" + e.alias);
        } else {
          this.$router.push({
            name: "jqjh_nav",
            params: {
              id: e.id,
              title: e.name,
            },
          });
        }
        return;
      }
      // console.log(e, i)
      e.isActive = true;
      this.tabs.forEach((m, n) => {
        if (n !== i) {
          m.isActive = false;
        }
      });
      // console.log(this.tabs)
      // this.routerPush("/" + e.alias)
      this.$router.push({
        name: e.alias,
        params: {
          title: e.name,
        },
      });
    },
    // 首页公共导航
    async getNavBrowse() {
      // let { data: res } = await http.post('api/auth/nav_browse')
      // console.log(res)
      this.tabs = [
        {
          data: null,
          id: 29,
          name: "首页",
          pid: 0,
          alias: "index",
          description: "",
          pic_pc: "",
          pic_phone: null,
        },
        {
          data: [
            {
              data: [
                {
                  id: 31,
                  name: "同步课堂",
                  pid: 30,
                  alias: "course_one",
                  description: "icon-kecheng",
                  pic_pc: "/upload/202309/202309070928045754.jpg",
                  pic_phone: null,
                },
                {
                  id: 32,
                  name: "精品专题",
                  pid: 30,
                  alias: "course_tow",
                  description: "icon-zhuantifuwu",
                  pic_pc: "/upload/202309/202309070927107028.jpg",
                  pic_phone: null,
                },
                {
                  id: 33,
                  name: "复习备考",
                  pid: 30,
                  alias: "fxbk",
                  description: "icon-a-kecheng2",
                  pic_pc: "/upload/202309/202309070927208922.jpg",
                  pic_phone: null,
                },
                {
                  id: 34,
                  name: "假期计划",
                  pid: 30,
                  alias: "jqjh",
                  description: "icon-jihuarenwu",
                  pic_pc: "/upload/202309/202309070927284233.jpg",
                  pic_phone: null,
                },
              ],
              id: 30,
              name: "课程中心",
              pid: 0,
              alias: "course",
              description: "",
              pic_pc: "/upload/202309/202309070926579276.jpg",
              pic_phone: null,
            },
            {
              data: [
                {
                  id: 37,
                  name: "在线刷题",
                  pid: 35,
                  alias: "zxst",
                  description: "",
                  pic_pc: "",
                  pic_phone: null,
                },
                {
                  id: 38,
                  name: "随堂练习",
                  pid: 35,
                  alias: "stlx",
                  description: "",
                  pic_pc: "",
                  pic_phone: null,
                },
              ],
              id: 35,
              name: "智能练习",
              pid: 0,
              alias: "znlx",
              description: "",
              pic_pc: "",
              pic_phone: null,
            },
            {
              data: null,
              id: 48,
              name: "同步名校",
              pid: 0,
              alias: "lnzt",
              description: "",
              pic_pc: "",
              pic_phone: null,
            },
            {
              data: null,
              id: 43,
              name: "心灵FM",
              pid: 0,
              alias: "xlfm",
              description: "",
              pic_pc: "/upload/202308/202308181330186341.jpg",
              pic_phone: null,
            },
          ],
          id: 1001,
          name: "学习广场",
          pid: 0,
          alias: "",
          description: "",
          pic_pc: "",
          pic_phone: null,
        },
      ];
      let { data: res } = await http.post("api/auth/nav_browse2", { pid: 4 });
      if (res.code == 0) {
        if (res.data.length) {
          res.data.forEach((e) => {
            this.tabs.push(e);
          });
        }
      }
      // if (res.code == 0) {
      this.tabs.forEach((e, i) => {
        if (i == 0) {
          e.isActive = true;
        } else {
          e.isActive = false;
        }
      });
      let index = this.tabs.findIndex((e) => e.name == "同步课堂");
      if (index !== -1) {
        let courseBrowse = res.data[index];
        window.sessionStorage.setItem(
          "courseBrowse",
          JSON.stringify(courseBrowse)
        );
      }
      // }
    },
    handleCommand(command) {
      let item = JSON.parse(command);
      console.log(item);
      if (item.name == "智能练习") {
        return this.$router.push({
          name: item.data[0].alias,
          params: {
            title: item.data[0].name,
            obj: JSON.stringify(item.data[0]),
          },
        });
      }
      // this.routerPush("/" + item.alias)
      this.$router.push({
        name: item.alias,
        params: {
          title: item.name,
          obj: command,
        },
      });
    },
    async getLinks() {
      let { data: res } = await http.post("api/auth/links");
      if (res.code == 0) {
        this.links = res.data;
        // console.log(this.links)
      }
    },
    async getSites() {
      let { data: res } = await http.post("api/auth/sites");
      if (res.code == 0) {
        this.sites = res.data;
        store.commit("setGrey", { grey: res.data.grey.name });
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", res.data.keywords.name);
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", res.data.description.name);
        // console.log(this.sites)
      }
    },
    async getSearch() {
      let { data: res } = await http.post("api/course/course_search", {
        name: this.searchForm.text,
      });
      if (res.code == 0) {
        console.log(res);
      }
    },
  },
};
</script>
<style  lang='less' scoped>
.home {
  overflow: hidden;
}

.header-top {
  width: 100vw;
  min-width: 1200px;
  height: 40px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-inner {
    width: 1200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-right {
    .btns {
      display: flex;
      align-items: center;

      .btn-item {
        margin-left: 16px;
        cursor: pointer;

        img {
          vertical-align: middle;
          margin-right: 5px;
          margin-top: -2px;
        }
      }
    }
  }
}

.header-logo {
  display: flex;
  width: 1200px;
  height: 105px;
  margin: 0 auto;
  align-items: center;

  .search-box {
    width: 520px;
    height: 45px;
    background-color: #fff;
    // box-sizing: border-box;
    border: 2px solid;
    margin-left: 120px;
    display: flex;

    > div {
      width: 80px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    input {
      width: 384px;
      height: 41px;
      line-height: 41px;
      border: none;
      background-color: #fff;
      box-sizing: border-box;
      padding-left: 20px;
      outline: none;
    }

    .el-button {
      display: inline-block !important;
      border: none !important;
      border-radius: 0 !important;
    }

    .el-select {
      width: 130px !important;
      border-radius: 0 !important;
    }
  }
}

.nav {
  width: 100vw;
  min-width: 1200px;
  position: relative;
  height: 50px;

  .nav-top {
    width: 100vw;
    height: 50px;
    background-color: rgba(252, 129, 2, 1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
  }

  .nav-box {
    width: 1200px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .tabs {
      width: 100%;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 70;

      .tab {
        cursor: pointer;
        height: 50px;
        padding: 0 10px;
        margin: 0 10px;
        line-height: 50px;
        background-color: rgba(252, 129, 22, 1);

        .el-dropdown {
          color: #fff !important;
          font-size: 16px !important;
          vertical-align: top;
        }
      }

      .tab:hover {
        background-color: rgb(245, 117, 5);
      }

      .tab-select {
        background-color: rgba(252, 129, 22, 1);
      }

      .tab-index0 {
        background-color: rgba(252, 129, 22, 0.4);
        width: 260px;
        text-align: center;
        margin: 0;
      }

      .tab-index0:hover {
        background: rgba(252, 129, 22, 0.4);
      }

      .tab-bgnone {
        background: none;
      }
    }

    .subs {
      width: 260px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
    }
  }
}

.foot {
  width: 100%;
  min-width: 1200px;
  height: 320px;
  background-color: #ffffff;

  .foot-inner {
    width: 1200px;
    height: 320px;
    box-sizing: border-box;
    padding: 40px;
    margin: auto;

    .foot-top {
      width: 100%;
      height: 141px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .foot-link-main {
        width: 590px;
        height: 141px;
        box-sizing: border-box;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            a {
              color: #888888;
              text-decoration: none;
            }

            a:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .foot-qrcode {
        width: 384px;
        height: 141px;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;

        .telephone {
          color: #666;

          div {
            line-height: 30px;

            span {
              color: #828282;
            }
          }
        }

        .down-app {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 120px;
            height: 120px;
            margin-bottom: 8px;
          }

          span {
            font-size: 13px;
            color: #78808d;
            height: 13px;
          }
        }
      }
    }

    .foot-bot {
      width: 100%;
      height: 100px;
      border-top: solid 1px #efeff0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bot-left {
        width: 40%;
        height: 40px;

        p {
          font-size: 13px;
          color: #888888;
          height: 13px;
          line-height: 13px;
        }
      }

      .bot-right {
        width: 60%;
        height: 40px;

        p {
          font-size: 14px;
          color: #888888;
          height: 6px;

          span {
            margin-right: 10px;
          }
        }

        a {
          font-size: 14px;
          color: #f56c6c;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>